
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import * as OM from '@/Model';

@Options({
    components: {
    }
})
export default class SuccessScanModal extends Vue {

    @Prop() homeCallback: any;
    @Prop() callback: any;

    created(){
    }

}
