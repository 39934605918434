
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({
    components: {
    }
})
export default class WrongScanModal extends Vue {

    @Prop({
        default: () => null
    }) rescueDate: Date;

    @Prop() callback: any;
    @Prop() homeCallback: any;

    created(){
    }

}
