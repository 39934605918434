
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({
    components: {
    }
})
export default class ErrorModal extends Vue {

    @Prop({
        default: ""
    }) title: string;

    @Prop({
        default: ""
    }) text: string;

    @Prop() callback: any;

    created(){
    }

}
